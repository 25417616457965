
@font-face {
  font-family: HipelessBrush;
  src: url("../fonts/HipelessBrush.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
font-family: HillstownSans;
src: url("../fonts/HillstownSans.otf");
font-weight: normal;
font-style: normal;
}
html{
  scroll-behavior: smooth;
}

body{
  background-color: grey !important;
  font-family: HipelessBrush;
  color: white;
  margin: 0;
  padding: 0;
  font-size: 1.75em;
  letter-spacing: 4px;
  overflow-x: hidden;
  cursor: url('./icons/green_can_new.png'),auto !important;
}

.yellow:hover{
  cursor: url('./icons/yellow_can_new.png'), auto;
}

.purple:hover{
  cursor: url('./icons/purple_can.png'), auto;
}

.active{
  cursor: url('./icons/purple_can.png'), auto !important;
}

.white:hover{
  cursor: url('./icons/white_can.png'), auto;
}

.black:hover{
  cursor: url('./icons/black_can.png'), auto;
}

.blue:hover{
  cursor: url('./icons/blue_can.png'), auto;
}

.cat:hover{
  cursor: url('./icons/rmouse.png'), auto;
}

.hidden{
  visibility: hidden;
}

a{
  color: white;
  text-decoration: none;
}

a:hover{
  color: #FEF102;
  text-decoration: none;
}

@-webkit-keyframes rotate {
  from { -webkit-transform: rotate(0) scale(2); }
  to { -webkit-transform: rotate(360deg) scale(2);}
}
@keyframes rotate {
  from { transform: rotate(0) scale(2); }
  to { transform: rotate(360deg) scale(2); }
}

/* checkbox */
.styledCheckbox {
  width: 5.2vh;
  height: 5.2vh;
  position: relative;
  margin: 0;
  background: black;;
  border-radius: 100%;
}
.styledCheckbox label {
  width: 4.2vh;
  height: 4.2vh;
  position: absolute;
  top: 0.45vh;
  left: 0.5vh;
  cursor: pointer;
  background: #fef102;
  border-radius: 100%;
}
.styledCheckbox label:after {
  content: '';
  width: 2.3vh;
  height: 1.2vh;
  position: absolute;
  top: 1vh;
  left: 0.7vh;
  border: 4px solid black;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  transform: rotate(-45deg);
}
.styledCheckbox label:hover::after {
  opacity: 0.3;
}
input[type=checkbox] {
  visibility: hidden;
}
.styledCheckbox input[type=checkbox]:checked + label:after {
  opacity: 1;
}
input[type=radio] {
  visibility: hidden;
}
.styledCheckbox input[type=radio]:checked + label:after {
  opacity: 1;
}

.styledCheckboxTxt{
  position: absolute;
  padding-left: 7vh; 
  padding-top: 1.2vh;
  font-size: 2.7vh;
  cursor: url('./icons/white_can.png'), auto !important;
  /* cursor: pointer; */
}

.miniinput{
  scale:0.7 !important;
}

.styledCheckboxTxtPayMob{
  position: relative;
  padding-left: 5vh; 
  padding-top: 1.2vh;
  font-size: clamp(1vh, 4vw ,2.4vh);
  cursor: pointer;
}

.styledCheckboxTxt:hover + .styledCheckbox label::after {
  opacity: 0.3;
}

.active {
  color: #c54def;
  /* text-decoration: line-through white; */
}

.active:hover {
  color: #c54def;
  /* text-decoration: line-through white; */
}

.masonryGrid {
  display: -webkit-box;
  display: -ms-flexbox; 
  display: flex;
  margin-left: -30px;
  width: 100%;
  overflow-y: scroll;
}

.masonryGridColumn:nth-child(even){
  padding-top: 7vh;
}

.masonryGridColumn:nth-child(even) > * > *{
  flex-direction: column-reverse;
  /*width: 100% !important;*/
}

.masonryGridColumn:nth-child(even) > * > * > * > div{
  top:auto;
  bottom: -10px;
}

.masonryGridColumn {
  padding-left: 30px;
  background-clip: padding-box;
}

@media screen and (max-width:972px){
  .masonryGrid{
    padding-left: 15px;
  }
}

/* Style your items */
.masonryGridColumn > div {
  margin-bottom: 30px;
}

.ShopLink{
  font-size:2.8vh;
  /* color:white; */
}

.ShopLink + .active{
  font-size:4.5vh;
  /* color:white; */
  /* text-decoration: line-through; */
  /* text-decoration:none; */
}

.active{
  position: relative;
}

.active:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 3px solid;
  border-color: inherit;
  color: white;
  
  -webkit-transform:rotate(-8deg);
  -moz-transform:rotate(-8deg);
  -ms-transform:rotate(-8deg);
  -o-transform:rotate(-8deg);
  transform:rotate(-8deg);
}

.shopCat{
  font-size: 3vh;
}
.shopCat .active{
  color: #fef102;
}
.shopCat .active:before{
  border-top: none;
}